<template>
  <div>
    <h1 class="title">Product Config</h1>
    <div class="columns">
        <div class="column" v-if="destinations">
            <div class="field">
                <label class="label">Search</label>
                <div class="control">
                    <input class="input is-fullwidth"
                           type="search"
                           placeholder="by Code and Name"
                           v-model="searchText"
                           v-on:keyup.enter="updateUrlAndLoadPage"
                           v-on:blur="updateUrlAndLoadPage" />
                </div>
            </div>
        </div>
        <div class="column" v-if="destinations">
            <div class="field">
                <label class="label">Destination</label>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model="filteredByDestinationId"
                                v-on:change="updateUrlAndLoadPage">
                            <option :value="null">All</option>
                            <option v-for="d in destinations" :value="d.Id" :key="d.Id">
                                {{
                  d.Name
                                }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="column" v-if="ContactGroups">
            <div class="field">
                <label class="label">Contact Group</label>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model="filteredByContactGroupId"
                                v-on:change="updateUrlAndLoadPage">
                            <option :value="null">All &amp; None</option>
                            <option v-for="d in ContactGroups" :value="d.Id" :key="d.Id">
                                {{
                  d.Name
                                }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="field">
                <label class="label">Page size</label>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model.number="pageSize"
                                v-on:change="updateUrlAndLoadPage">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="columns">
      <div class="column is-half" style="display:flex">
        <b-pagination
          v-if="products"
          size="is-small"
          :total="products.TotalRows"
          :current.sync="currentPaginationPage"
          :per-page="products.Limit"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          v-on:change="pageChange"
        ></b-pagination>
      </div>
      <div class="column has-text-right">
        <button class="button is-primary is-medium" v-on:click="addProduct">
          Add Product
        </button>
      </div>
    </div>

    <table class="table is-size-6 is-fullwidth middlealign" v-if="products">
      <thead>
        <tr>
          <th>
            <label class="checkbox-table">
              <input
                type="checkbox"
                :checked="selectedProducts.length == products.Results.length"
                v-on:change="selectAllClick"
              />
              <span></span>
            </label>
          </th>

          <th
            v-if="selectedProducts.length > 0"
            :colspan="7 + (showXmasField ? 1 : 0)"
          >
            {{ selectedProducts.length }} products selected ready for bulk
            action
          </th>

          <th
            v-if="selectedProducts.length > 0"
            colspan="2"
            class="has-text-vertical-middle has-text-right"
          >
            <b-dropdown aria-role="list" class="is-right">
              <button class="button is-primary is-small" slot="trigger">
                <span>Bulk Action</span>
                &nbsp;
                <i class="fas fa-caret-down"></i>
              </button>
              <b-dropdown-item
                aria-role="listitem"
                v-on:click="showBulkContactGroupDialog = true"
                >Set Contact Group</b-dropdown-item
              >
            </b-dropdown>
          </th>

          <th v-if="selectedProducts.length == 0">#</th>
          <th v-if="selectedProducts.length == 0">Name</th>
          <th v-if="selectedProducts.length == 0">Code</th>
          <th v-if="selectedProducts.length == 0">Publisher Code</th>
          <th v-if="selectedProducts.length == 0">Destination</th>
          <th v-if="selectedProducts.length == 0">Contact Group</th>
          <th v-if="showXmasField && selectedProducts.length == 0">
            Xmas Data
          </th>
          <th v-if="selectedProducts.length == 0">Custom Data?</th>
          <th v-if="selectedProducts.length == 0" title="Enabled">Enbl.</th>
          <th v-if="selectedProducts.length == 0" class="has-text-right">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="p in products.Results"
          :key="p.Id"
          :class="{
            'is-highlighted': selectedProducts.indexOf(p) >= 0,
          }"
        >
          <td>
            <label class="checkbox-table">
              <input type="checkbox" v-model="selectedProducts" :value="p" />
              <span></span>
            </label>
          </td>
          <td>{{ p.Id }}</td>
          <td>
            <b>{{ p.Name }}</b>
          </td>
          <td>
            <router-link
              title="Search fulfilment by this code"
              :to="'/fulfilment?query=' + p.FourLetterCode"
              >{{ p.FourLetterCode }}</router-link
            >
          </td>
          <td>
            <router-link
              title="Find other Product Configs for this Publisher code"
              :to="'/product-config?search=' + p.FiveLetterCode"
              >{{ p.FiveLetterCode }}</router-link
            >
          </td>

          <td>
            <router-link
              title="Manage this product code"
              :to="'batch-destination?nameContains=' + p.BatchDestination.Name"
              >{{ p.BatchDestination.Name }}</router-link
            >
          </td>
          <td>
            <router-link
              v-if="p.ContactGroupId"
              title="Manage this contact group"
              :to="
                'contact-group?nameContains=' +
                  getContactGroupName(p.ContactGroupId)
              "
              >{{ getContactGroupName(p.ContactGroupId) }}</router-link
            >
            <span v-else>Not Set</span>
          </td>
          <td v-if="showXmasField">{{ p.XmasValue }}</td>
          <td>
            <span v-if="p.Custom !== null && p.Custom.length > 0" class="tag"
              >Yes</span
            >
          </td>
          <td>
            <span
              v-on:click="confirmToggleEnabled(p)"
              v-if="p.Enabled"
              class="tag is-success cur"
              >Yes</span
            >
            <span
              v-on:click="confirmToggleEnabled(p)"
              v-else
              class="tag is-danger cur"
              >No</span
            >
          </td>
          <td style="width:170px;">
            <div class="buttons" style="justify-content: flex-end">
              <button
                class="button is-small"
                v-on:click="productLinkupEditing = p"
              >
                Edit / View
              </button>
              <button
                class="button is-small is-danger"
                v-on:click="confirmDeleteProduct(p)"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="columns">
      <div class="column is-four-fifths" style="display:flex">
        <b-pagination
          v-if="products"
          size="is-small"
          :total="products.TotalRows"
          :current.sync="currentPaginationPage"
          :per-page="products.Limit"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          v-on:change="pageChange"
        ></b-pagination>
      </div>
      <div class="column has-text-right">
        <button class="button is-primary is-medium" v-on:click="addProduct">
          Add Product
        </button>
      </div>
    </div>

    <product-insert-or-update
      v-if="productLinkupEditing"
      :destinations="destinations"
      :product-linkup.sync="productLinkupEditing"
      v-on:close="closeProductEditingDialog"
      v-on:add="addProductEditingDialog"
    ></product-insert-or-update>

    <product-bulk-set-contact-group
      v-if="showBulkContactGroupDialog"
      :product-linkups="selectedProducts"
      :contact-groups="ContactGroups"
      v-on:close="closeProductBulkSetContactGroupDialog"
    ></product-bulk-set-contact-group>
  </div>
</template>
<script>
import { baseMixin } from "../../mixins/baseMixin.js";

export default {
  components: {
    productInsertOrUpdate: () =>
      import("../components/organisms/productInsertOrUpdate.vue"),
    productBulkSetContactGroup: () =>
      import("../components/organisms/productBulkSetContactGroup.vue"),
  },
  mixins: [baseMixin],
  data: function() {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      products: null,
      destinations: null,
      editingProduct: null,
      currentPaginationPage: 0,
      pageSize: 25,
      productLinkupEditing: null,
      filteredByDestinationId: null,
      filteredByContactGroupId: null,
      searchText: "",

      ContactGroups: null,
      selectedProducts: [],
      showBulkContactGroupDialog: false,
      // Config: null,
      // DestinationProductsDialog: null
    };
  },
  created() {
    this.currentPaginationPage = this.$route.query.page
      ? this.$route.query.page
      : 1;

    this.pageSize = this.$route.query.take ? this.$route.query.take : 25;
    this.filteredByDestinationId = this.$route.query.destinationId || null;
    this.filteredByContactGroupId = this.$route.query.contactGroupId || null;
    this.searchText = this.$route.query.search ? this.$route.query.search : "";

    this.loadDestinationOptions();
    this.loadContactGroups();
    this.loadPage();
  },
  methods: {
    updateUrlAndLoadPage() {
      var url = "product-config?take=" + this.pageSize;

      if (this.currentPaginationPage)
        url += "&page=" + this.currentPaginationPage;

      if (this.filteredByDestinationId)
        url += "&destinationId=" + this.filteredByDestinationId;

      if (this.filteredByContactGroupId)
        url += "&contactGroupId=" + this.filteredByContactGroupId;

      if (this.searchText) url += "&search=" + this.searchText;

      this.$router.push(url);
      this.loadPage();
    },
    loadPage() {
      var url = this.getApiProductUrl(this.currentPaginationPage);
      this.axios.get(url).then((response) => {
        this.products = response.data;
      });
    },
    loadContactGroups() {
      this.axios
        .get(this.APIUrl + "/contact-group/basic?groupType=Product")
        .then((response) => {
          this.ContactGroups = response.data;
        });
    },
    getApiProductUrl(pageNumber1Index) {
      var zeroIndex =
        pageNumber1Index > 0 ? pageNumber1Index - 1 : pageNumber1Index;

      var url =
        this.APIUrl +
        "/product?take=" +
        this.pageSize +
        "&pageNumber=" +
        zeroIndex;

      if (this.filteredByDestinationId !== null)
        url += "&batchDestinationId=" + this.filteredByDestinationId;

      if (this.filteredByContactGroupId !== null)
        url += "&contactGroupId=" + this.filteredByContactGroupId;

      if (this.searchText !== null) url += "&search=" + this.searchText;

      return url;
    },
    loadDestinationOptions() {
      var url = this.APIUrl + "/destination/all/basic";
      this.axios.get(url).then((response) => {
        this.destinations = response.data;
      });
    },
    addProduct() {
      this.productLinkupEditing = {
        Id: null,
        Name: "",
        FourLetterCode: "",
        FiveLetterCode: "",
        BatchDestinationId: null,
        Enabled: true,
        Custom: "",
      };
    },
    pageChange(page) {
      this.currentPaginationPage = page;
      this.updateUrlAndLoadPage();
    },

    confirmToggleEnabled(p) {
      var isEnabled = p.Enabled;

      var msg = p.Enabled
        ? "Are you sure you want to <b>DISABLE</b> this '<b>" +
          p.Name +
          "</b>', no fulfilments will be sent"
        : "Are you sure you want to <b>ENABLE</b> this '<b>" +
          p.Name +
          "</b>', it will start sending fulfilments within the assoicated destination";

      var title = (p.Enabled ? "Disable " : "Enable ") + p.Name + "?";

      this.$dialog.confirm({
        title: title,
        message: msg,
        _type: "is-danger",
        get type() {
          return this._type;
        },
        set type(value) {
          this._type = value;
        },
        onConfirm: () => this.toggleEnabled(p),
      });
    },
    toggleEnabled(p) {
      var newState = !p.Enabled;
      var url = this.APIUrl + "/product/" + p.Id + "/enabled/" + newState;
      this.axios.put(url).then((response) => {
        p.Enabled = newState;
        var state = newState ? "ENABLED" : "DISABLED";
        this.$toast.open(
          "Successfully updated product <b>'" +
            p.Name +
            "'</b> state to: <b>'" +
            state +
            "'</b>"
        );
      });
    },
    closeProductEditingDialog() {
      this.loadContactGroups();

      this.productLinkupEditing = null;
    },
    addProductEditingDialog(p) {
      this.products.Results.unshift(p);
      this.closeProductEditingDialog();
    },

    confirmDeleteProduct(p) {
      var msg =
        "Are you sure you want to <b>DELETE</b> product <b>" +
        p.Name +
        " (" +
        p.FourLetterCode +
        ")</b>?";

      msg +=
        "<br><br>Linked with destination: <b>" +
        p.BatchDestination.Name +
        "</b> products";

      this.$dialog.confirm({
        title: "Delete " + p.Name,
        message: msg,
        type: "is-danger",
        confirmText: "Delete",
        onConfirm: () => this.deleteProduct(p),
      });
    },
    deleteProduct(p) {
      var url = this.APIUrl + "/product/" + p.Id;
      this.axios
        .delete(url)
        .then((response) => {
          this.$toast.open(
            "Successfully deleted product <b>'" + p.Name + "'</b>"
          );

          this.loadPage();
        })
        .catch(this.catchError);
    },

    selectAllClick() {
      if (this.selectedProducts.length == this.products.Results.length) {
        this.selectedProducts = [];
      } else {
        this.selectedProducts = this.products.Results;
      }
    },
    closeProductBulkSetContactGroupDialog(products) {
      if (products != null) {
        //if products passed back, then they have been updated
        this.selectedProducts = []; //unselect products
        this.updateProductRecord(products);
      }

      this.showBulkContactGroupDialog = false;
    },
    updateProductRecord(list) {
      var products = this.products.Results;
      for (var i = 0; i < list.length; i++) {
        var p = list[i];
        this.$set(products, products.findIndex((x) => x.Id === p.Id), p);
      }
    },
    getContactGroup(id) {
      return this.ContactGroups.filter((c) => c.Id === id)[0];
    },
    getContactGroupName(id) {
      if (
        this.ContactGroups &&
        this.ContactGroups.filter((c) => c.Id === id).length > 0
      ) {
        return this.ContactGroups.filter((c) => c.Id === id)[0].Name;
      } else {
        return "";
      }
    },
  },
  computed: {
    showXmasField() {
      return new Date().getMonth() >= 7;
    },
  },
};
</script>
